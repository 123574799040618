import './LanguageSwitch.css'
import { useState } from 'react'

function LanguageSwitch(props) {
    const [isEnSwitched, setEn] = useState(false)
    var onLanguageSwitch = props.onLanguageSwitch === undefined ? (language) => {} : props.onLanguageSwitch
    var enTop = window.innerWidth > 480 ? (isEnSwitched ? "-50px": "10px") : (isEnSwitched ? "-30px": "10px")
    const switchLanguage = () => {
        onLanguageSwitch(!isEnSwitched)
        setEn(!isEnSwitched)
    }
    return <div className="language-switch" onClick={switchLanguage}>
        <div className="ru-language-label" style={{top: isEnSwitched ? "-40px": "0px"}}>ru</div>
        <div className="en-language-label" style={{top: enTop}}>en</div>
    </div>
}

export default LanguageSwitch