import './NavigationArrow.css'

function NavigationArrow(props) {
    let iconName
    if (props.navigationDirection === "up") {
        iconName = "arrow-up-line"
    } else {
        iconName = "arrow-down-line"
    }

    return <div className="direction-arrow">
        <div className="label">
            about me
        </div>
        <div className="arrow">
            <i className={`ri-${iconName} navigation-arrow`}></i>
        </div>
    </div>

}

export default NavigationArrow