import "./Directions.css"
import { useState, useLayoutEffect } from "react";
function useWindowSize() {
  const [size, setSize] = useState([0, 0]);
  useLayoutEffect(() => {
    function updateSize() {
      setSize([window.innerWidth, window.innerHeight]);
    }
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}


function Direction(props) {
  var offset
  const [width, height] = useWindowSize()
  if (width > 480) {
    if (props.offset !== undefined) {
      offset = -25 + ((width / 4) * props.offset)
    } else {
      offset = -50
    }
  } else {
    offset = 0
  }


  return <div style={{ backgroundColor: props.color }} className="direction">
    <span style={{ marginLeft: offset === 0 ? 0 : offset }}>{props.name}</span>
  </div>
}

export default Direction;