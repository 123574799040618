import Direction from "./Direction"
import NavigationArrow from "./NavigationArrow"
import './DirectionsPage.css'
import LanguageSwitch from "./LanguageSwitch"
function DirectionsPage() {
    return <div className="full-cover">
        <div className="directions-menu">
            <Direction name="golang" color="#B4D1FE" />
            <Direction name="python" color="#F9D861" offset={1} />
            <Direction name="javascript" color="#F7C881" offset={2} />
            <Direction name="another" color="#E9FAC5" offset={3} />
        </div>

        <div className="bottom-row">
            <div className="page-name">
                my directions
            </div>
            <NavigationArrow />
            <LanguageSwitch/>
        </div>
    </div>
}

export default DirectionsPage